import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from '../../components/ui/Wrapper';
import SectionTitle from '../../components/ui/section-title';
import SwiperSlider from '../../components/ui/swiper';
import Testimonial from '../../components/testimonial';
import { TestimonialWrappper } from './section.style';

const TestimonialArea = ({ title, ...props }) => {
  const testmonialDataQuery = useStaticQuery(graphql`
    {
      allContentfulTestimonial(filter: { availableOn: { eq: "Mais Militar" } }) {
        nodes {
          id
          slug
          authorName
          authorImage {
            fluid(quality: 100, toFormat: WEBP) {
              ...GatsbyContentfulFluid
            }
          }
          authorDesignation {
            title
          }
          responsibleName
          responsibleReview {
            responsibleReview
          }
          subject
          review {
            review
          }
          availableOn
        }
      }
    }
  `);
  const testimonials = testmonialDataQuery.allContentfulTestimonial.nodes;
  const { subtitle, sectionTitleStyle, slider, sliderStyle, itemsFilter } = props;

  const sortTestimonials = (a, b) => {
    if (!itemsFilter || itemsFilter.length === 0) {
      return true;
    }
    return itemsFilter.indexOf(a.slug) - itemsFilter.indexOf(b.slug);
  };

  const filterTestimonials = testimonial => {
    if (!itemsFilter || itemsFilter.length === 0) {
      return true;
    }
    return itemsFilter.includes(testimonial.slug);
  };

  return (
    <TestimonialWrappper>
      <Container fluid px={0}>
        <Row>
          <Col lg={12}>
            <SectionTitle {...sectionTitleStyle} title={title} subtitle={subtitle} />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <SwiperSlider {...sliderStyle} settings={slider}>
              {testimonials
                .sort(sortTestimonials)
                .filter(filterTestimonials)
                .map(testimonial => (
                  <div className="item" key={testimonial.id}>
                    <Testimonial testimonial={testimonial} />
                  </div>
                ))}
            </SwiperSlider>
          </Col>
        </Row>
      </Container>
    </TestimonialWrappper>
  );
};

TestimonialArea.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  sectionTitleStyle: PropTypes.object,
  slider: PropTypes.object,
  sliderStyle: PropTypes.object,
  itemsFilter: PropTypes.arrayOf(PropTypes.string),
};

TestimonialArea.defaultProps = {
  title: 'Por que escolher o <span>Mais Militar?</span>',
  subtitle: 'DEPOIMENTOS',
  itemsFilter: [],
  sectionTitleStyle: {
    mb: '60px',
    responsive: {
      small: {
        mb: '47px',
      },
    },
  },
  slider: {
    autoplay: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    loop: true,
    speed: 3000,
    spaceBetween: 50,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  },
  sliderStyle: {
    opacityStyle: true,
    pagination: {
      mt: '28px',
    },
  },
};

export default TestimonialArea;
