import styled from 'styled-components';

export const ItemProduct = styled.div`
  .bright-price-rating-wrapper {
    display: flex;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;
    font-weight: 500;
    font-size: 26px;
    color: rgb(51, 51, 51);
  }
  .bright-price-rating-wrapper .price {
    text-align: center;
  }
  .bright-price-rating-wrapper del {
    font-size: 22px;
    margin-block-end: 0px;
    color: rgb(171, 171, 171);
    top: 10px;
  }
`;
