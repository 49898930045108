import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Image from '../Image';
import {
  TestimonialWrap,
  TestimonialSubject,
  TestimonialReview,
  AuthorInfoWrap,
  AuthorMedia,
  AuthorInfo,
  AuthorName,
  ResponsibleName,
} from './testimonial.style';

const Testimonial = ({ testimonial, testimonialStyle }) => {
  const {
    authorImage,
    authorName,
    authorDesignation,
    responsibleName,
    responsibleReview,
    subject,
    review,
  } = testimonial;
  const { wrapperStyle, infoStyle, imageStyle, reviewStyle, subjectStyle } = testimonialStyle;

  return (
    <TestimonialWrap {...wrapperStyle}>
      <AuthorInfoWrap {...infoStyle}>
        {authorImage && (
          <AuthorMedia {...imageStyle}>
            <Image fluid={authorImage} alt={authorName} title={authorName} />
          </AuthorMedia>
        )}
        {(authorName || authorDesignation) && (
          <AuthorInfo>
            {authorName && <AuthorName>{authorName}</AuthorName>}
            {authorDesignation && <span>{authorDesignation.title}</span>}
            {responsibleName ? (
              <ResponsibleName>
                <strong>Responsável:</strong>
                {` ${responsibleName}`}
              </ResponsibleName>
            ) : null}
          </AuthorInfo>
        )}
      </AuthorInfoWrap>
      {subject && <TestimonialSubject {...subjectStyle} dangerouslySetInnerHTML={{ __html: subject }} />}
      {review && (
        <TestimonialReview
          {...reviewStyle}
          dangerouslySetInnerHTML={{
            __html: `${responsibleReview ? '<strong>Depoimento do aluno:</strong> ' : ''}${review.review}`,
          }}
        />
      )}

      {responsibleReview && (
        <TestimonialReview
          {...reviewStyle}
          mt={24}
          dangerouslySetInnerHTML={{
            __html: `<strong>Depoimento do responsável:</strong> ${responsibleReview.responsibleReview}`,
          }}
        />
      )}
    </TestimonialWrap>
  );
};

Testimonial.propTypes = {
  testimonial: PropTypes.shape.apply({
    authorImage: PropTypes.object,
    rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    authorName: PropTypes.string,
    responsibleName: PropTypes.string,
    authorDesignation: PropTypes.string,
    review: PropTypes.string,
    responsibleReview: PropTypes.string,
  }).isRequired,
  testimonialStyle: PropTypes.object,
};

Testimonial.defaultProps = {
  testimonialStyle: {
    wrapperStyle: {
      bgColor: '#fff',
      p: '50px 120px',
      borderRadius: '5px',
    },
    subjectStyle: {
      mb: '15px',
    },
    reviewStyle: {
      fontSize: '18px',
      color: 'textColorLight',
    },
    infoStyle: {
      mt: '20px',
    },
    imageStyle: {
      mr: '30px',
      circle: true,
    },
  },
};

export default Testimonial;
