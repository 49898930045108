import React from 'react';
import PropTypes from 'prop-types';
import { MdTrendingFlat } from 'react-icons/md';
import { Link } from 'gatsby';
import ReactVivus from 'react-vivus';
import Button from '../../ui/button';
import {
  BoxIconWrap,
  BoxIconInner,
  BoxIconImg,
  BoxIconContent,
  BoxIcontitle,
  BoxIconText,
  BoxIconBtn,
} from './box-icon.style';
import Image from '../../Image';

const BoxIcon = props => {
  const { id, icon, title, desc, path, ...restProps } = props;
  const { boxStyles, titleStyles, textStyles, buttonStyles } = restProps;

  return (
    <BoxIconWrap>
      <BoxIconInner {...boxStyles}>
        <BoxIconImg>
          <Image fluid={icon} />
        </BoxIconImg>
        <BoxIconContent>
          {title && <BoxIcontitle {...titleStyles}>{title}</BoxIcontitle>}
          {desc && <BoxIconText {...textStyles}>{desc}</BoxIconText>}
          {path && (
            <BoxIconBtn>
              <Button to={path} as={Link} {...buttonStyles} icon={<MdTrendingFlat />}>
                Discover now
              </Button>
            </BoxIconBtn>
          )}
        </BoxIconContent>
      </BoxIconInner>
    </BoxIconWrap>
  );
};

BoxIcon.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  icon: PropTypes.object.isRequired,
  desc: PropTypes.string,
  path: PropTypes.string,
  buttonStyles: PropTypes.object,
};

BoxIcon.defaultProps = {
  buttonStyles: {
    varient: 'texted',
    fontWeight: 400,
    hover: 'false',
    icondistance: '8px',
  },
  title: null,
  desc: null,
  path: null,
};

export default BoxIcon;
